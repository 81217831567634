import { template as template_07f9877e22a44a6ab590a2745607c13d } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
export default class TrackSelected extends Component {
    @action
    onToggle(e) {
        if (e.target.checked) {
            this.args.selectedList.addObject(this.args.selectedId);
        } else {
            this.args.selectedList.removeObject(this.args.selectedId);
        }
    }
    static{
        template_07f9877e22a44a6ab590a2745607c13d(`
    <span class={{@class}} ...attributes>
      <input {{on "input" this.onToggle}} type="checkbox" />
    </span>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
