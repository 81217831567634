import { template as template_a4aee55e6f2045ebb6e8659bb90a9e89 } from "@ember/template-compiler";
const WelcomeHeader = template_a4aee55e6f2045ebb6e8659bb90a9e89(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
